import { ApiClient } from '../../../../Libs/xhr/ApiClient';
import { IErrorHandler } from '../../../../Libs/xhr/ErrorHandler';

let XHRClient: ApiClient;

const initializeApiClient = (
    baseUrl: string,
    errorHandler: IErrorHandler,
    timeout?: number
): void => {
    XHRClient = new ApiClient({ baseUrl, errorHandler, timeout });
};
export { initializeApiClient, XHRClient as xhrClient };
