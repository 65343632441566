import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../Configs/store/AppReducer';

interface IntlWrapperProps {
    children?: React.ReactNode;
}

//* TODO à revoir
const IntlWrapper: React.FunctionComponent<IntlWrapperProps> = ({ children }: IntlWrapperProps) => {
    const i18n = useSelector((state: AppState) => state.I18n);
    return <IntlProvider {...i18n}>{children}</IntlProvider>;
};

export { IntlWrapper };
